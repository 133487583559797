/*.block:not(.block__shop__cart) {display:none;}*/

.block__shop__cart {
    
    .block__shop__cart__list {

        .block__shop__cart__list__body {
            display:flex;
            flex-direction: column;
            gap: var(--spacer-2);
            margin-top: var(--spacer-5);

            .listelement {
                display:flex;
                align-items: center;
                gap:1rem;

                .listelement__image {
                    width:72px;
                    height:72px;

                    @include media-breakpoint-down(sm) {
                        display:none;
                    }
                    
                    figure {
                        width:100%;
                        height:100%;
                        margin-bottom: 0;
                        position:relative;

                        img {
                            width:100%;
                            height:100%;
                            object-fit: contain;
                        }

                        &::after {
                            @include image-overlay;
                        }
                    }
                }

                .listelement__meta {
                    display:flex;
                    flex-direction: column;
                    align-items: flex-start;
                    flex:1;
                    gap:.25rem;


                    .listelement__meta__row1,
                    .listelement__meta__row2 {
                        display:grid;
                        grid-template-columns: 7fr 7rem 1fr;
                        gap:var(--spacer-1);
                        width:100%;
                        align-items: center;
        
                        @include media-breakpoint-up(sm) {
                            grid-template-columns: 6fr 4fr 1fr;
                        }
        
                        @include media-breakpoint-up(md) {
                            grid-template-columns: 6fr 3fr 1fr;
                        }
        
                        @include media-breakpoint-up(lg) {
                            grid-template-columns: 6fr 3fr 1fr;
                        }
        
                        @include media-breakpoint-up(xxl) {
                            grid-template-columns: 7fr 2fr 1fr;
                        }
                    }

                    .listelement__meta__title {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        margin-bottom: 0.75rem;
                        
                        h2 {
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-size: var(--bs-body-font-size);
                            font-weight:700;
                        }
                        
                        .listelement__meta__title__variant {
                            margin-bottom: 0;
                            font-size: calc(var(--bs-body-font-size) * .8);
                            color: #626871;
                        }
                    }

                    .listelement__meta__variantTitle {
                        color: #626871;
                        font-size: calc(var(--bs-body-font-size) * .75);
                    }

                    .listelement__meta__price {
                        margin-top:.25rem;
                        font-family: 'Wix Madefor Display', sans-serif;
                        font-size: calc(var(--bs-body-font-size) * .875);
                        font-weight:700;
                        
                        span {
                            font-family: 'Wix Madefor Text', sans-serif;
                            font-size: calc(var(--bs-body-font-size) * .625);
                            font-weight:500;
                        }
                    }

                    .listelement__meta__stock {
                        position:relative;
                        font-family: 'Wix Madefor Display', sans-serif;
                        font-size: calc(var(--bs-body-font-size) * .875);
                        font-weight:500;
                        padding-left:.875rem;

                        &::before {
                            @include fontawesomesharp;
                            content:"\f111";
                            color: #7C7C8D; /* When not not in stock */
                            font-weight:bold;
                            font-size: .4rem;                        
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            left:0;                                    
                        }                        

                        /* Product in stock */
                        @at-root .block__shop__cart .listelement.in-stock .listelement__meta__stock::before {
                            color: #5EC094!important;
                        }

                        /* Backorder */
                        @at-root .block__shop__cart .listelement.backorder .listelement__meta__stock::before {
                            color: #F68D68!important;
                        }
                    }
                }

                .listelement__qty {
                    display:flex;
                    flex-direction: column;
                    gap:.25rem;

                    .qty {
                        display: inline-block;
                        max-width:125px;
                
                        .input-group {
                        display: inline-flex;
                        align-items: center;
                        gap: .5rem;
                        border: 2px solid var(--color-custom-1-hex);
                        border-radius: 100px;
                        padding-inline: 2px;
                        background-color: var(--color-white-hex);

                        @include media-breakpoint-up(sm) {
                            padding-inline: 4px;
                        }
                
                        input {
                            font-weight:400;
                            text-align: center;
                            height: 30px;
                            width: 30px;
                            border: 0;
                            padding-inline: 0;
                            background-color:transparent;

                            @include media-breakpoint-up(sm) {
                                height: 42px;
                            }              
                
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                
                            -moz-appearance: textfield;
                        }
                
                        button {
                            width: 30px;
                            height: 30px;
                            padding: 0;
                            line-height: initial;
                            border: 0;
                            font-size: 1.45rem;
                            font-weight:500;
                            border-radius: 100% !important;

                            @include media-breakpoint-up(sm) {
                                width: 34px;
                                height: 34px;
                            }                            
                
                            &:hover,
                            &:focus-visible {
                                color: #fff;
                                background: var(--color-custom-1-hex);
                            }
                        }
                        }
                    }
                }

                /*
                .listelement__favorite {

                    @include media-breakpoint-down(lg) {
                        justify-content: flex-end;
                    }

                    a {
                        &::after {
                            @include fontawesomesharp;
                            content:"\f004";
                        }

                        &.active,
                        &:hover,
                        &:focus-visible {
                            font-weight:bold;
                        }
                    }
                }
                */

                .listelement__delete {

                    @include media-breakpoint-down(lg) {
                        justify-content: flex-end;
                    }                    

                    a {
                        svg{
                            width:14px;
                            height:20px;
                        }
                    }
                }
            }
        }
    }

    .block__shop__cart__summary {
        position:sticky;
        top: calc(var(--header-height) + var(--spacer-2));

        /*
        .block__shop__cart__summary__header {
            h2 {
                font-size:calc(var(--bs-body-font-size)*1.5);
            }
        }
        */

        .block__shop__cart__summary__body {
            margin-top: var(--spacer-3);

            > div {
                + div {
                    border-top:1px solid #EFF0F3;
                    padding-top:var(--spacer-1);
                    margin-top:var(--spacer-1);
                }
            }

            table {
                tr {
                    th {
                        font-weight: normal;
                    }

                    th,
                    td {
                        padding-block:0;

                        &:first-child {
                            padding-left:0;
                        }

                        &:last-child {
                            text-align: right;
                            padding-right:0;
                        }
                    }


                    &.totalExShipping {
                        font-family: 'Wix Madefor Display', sans-serif;
                        font-weight:600;
                    }

                    &.discount {
                        font-family: 'Wix Madefor Display', sans-serif;
                        font-weight:600;
                        color: #ce3535;
                    }

                    &.shipping {
                        font-family: 'Wix Madefor Display', sans-serif;
                        font-weight:600;
                    }

                    &.totalVat {
                        > * {
                            font-size:calc(var(--bs-body-font-size)*1.25);
                            font-family: 'Wix Madefor Display', sans-serif;
                            font-weight:700;
                        }
                    }
                }
            }
        }

        .block__shop__cart__summary__footer {
            display:flex;
            flex-direction: column;
            gap: var(--spacer-2);
            margin-top: var(--spacer-4);
            text-align:center;

            .bttn--checkout {
                &::before {
                   content:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2.49414H2.38006C3.28327 2.49414 4.0744 3.09951 4.31051 3.97131L4.81592 5.83743M4.81592 5.83743L6.03262 13.0712C6.11736 13.5749 6.56816 13.9339 7.07811 13.9035L15.788 13.3858C16.2909 13.3559 16.693 12.9566 16.7265 12.454L17.0957 6.90381C17.1341 6.32667 16.6763 5.83743 16.0979 5.83743L4.81592 5.83743Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M9.16406 17.083C9.16406 17.7734 8.60442 18.333 7.91406 18.333C7.22371 18.333 6.66406 17.7734 6.66406 17.083C6.66406 16.3927 7.22371 15.833 7.91406 15.833C8.60442 15.833 9.16406 16.3927 9.16406 17.083Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M15.8359 17.083C15.8359 17.7734 15.2763 18.333 14.5859 18.333C13.8956 18.333 13.3359 17.7734 13.3359 17.083C13.3359 16.3927 13.8956 15.833 14.5859 15.833C15.2763 15.833 15.8359 16.3927 15.8359 17.083Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M8.75255 10.9688L8.33594 10.9688' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M14.1692 10.9687L10.8359 10.9688' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M14.1667 8.50488L7.5 8.50488' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                   margin-right:.675rem;
                   vertical-align: middle;
                }
            }

            .link--discount {
                font-family: 'Wix Madefor Display', sans-serif;
                font-weight:600;

                svg{
                    width:14px;
                    height:25px;
                    margin-left:1rem;
                    @include transition-transform;
                }
                
                &[aria-expanded="true"] {
                    svg {
                        transform:rotate(-180deg);
                    }
                }
            }

            .checkout-discount {
                #cart__discount__input {
                    background-color:transparent;
                    border:1px solid var(--bs-body-color);
                }

                button {
                    width:100%;
                }
            }
        }
    }    
}