[aria-label="breadcrumbs"] {
    width:100%;
    display:block;
    margin-top: calc(var(--margin-default) / 3 * 2);
    
    @media screen and (min-height: 770px) {
        margin-top: var(--margin-default);
    }
}

$pluginName: "breadcrumbs";

// The text for the expand pseuodo-element.
$expand: "...";

// The text for the collapse pseudo-element.
// Set this to an empty string to suppress the collapse element when expanded.
$collapse: "\e404";

// Set this to false to disable debug styles.
$debug: false;

.#{$pluginName} {
    box-sizing: border-box;
    background-color: rgb(0 0 0 / 30%);
    border-radius: var(--border-radius-default);
    padding: 0.25rem 1rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    @if $debug {
        outline: 1px solid blue;
    }

    white-space: nowrap;
    list-style: none;
  
    > li {
        color: var(--color-white-hex);
        font-size:.75rem;
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;

        a {
            color: var(--color-white-hex);
            text-decoration: none;

            &:hover,
            &:focus,
            &:focus-visible {
                color: var(--color-white-hex);
                text-decoration: underline;
            }
        }

        .dropdown {
            display:inline;

            [data-bs-toggle="dropdown"] {
                &::after {
                    border:0;
                    @include fontawesome;        
                    content:"\f107";
                    vertical-align: inherit;
                    margin-left:.5rem;
                }

                &[aria-expanded="true"] {
                    &::after {
                        content:"\f107";
                        transform:rotate(-180deg);
                    }
                }
            }

            .dropdown-menu {
                background-color: rgb(0 0 0 / 30%);
                backdrop-filter: blur(5px);
                padding: 1rem;
                transform:translateY(1.875rem)!important;
                border-radius:1rem;
                max-height:300px;
                overflow-y: scroll;
                
                /* Track */
                &::-webkit-scrollbar {
                    width: .35rem; /* width of the entire scrollbar */
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background-color: #888; /* color of the draggable scroll */
                    border-radius: .25rem; /* roundness of the scroll thumb */
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background-color: #fff; /* color of the draggable scroll on hover */
                }

                
                li {
                    a {
                        font-size:.75rem;

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            background:transparent;
                        }
                    }
                }
            }
        }
    
        &:before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z" fill="%23fff"/></svg>') center no-repeat;
        }
    
        &:first-child {
        
            a {
                display:flex;
                flex-direction: column;
                justify-content: center;

                svg {
                    width: .75rem;
                    height: .75rem;
                }
            }
        
            &:before {
                display: none;
            }
        }
    }
   
    &.#{$pluginName}_overflow {
        white-space: normal;
    
        &.#{$pluginName}_expanded {
        white-space: normal;

            li {
                display: inline-block;

                a {
                    color: var(--color-white-hex);
                    text-decoration:none;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        color: var(--color-white-hex);
                        text-decoration: underline;
                    }

                    svg {
                        width:.75rem;
                        height:.75rem;
                    }
                }
            }

            .#{$pluginName}__toggle {
                
                @if $collapse == '' {
                    display: none;            
                }
                
                a:before {
                    display: inline-block;
                    @include fontawesome;
                    content: $collapse;
                }
            }
        }
        
        > li {
            display: none;
        
            &:last-child,
            &:first-child,
            &.#{$pluginName}__toggle,
            &.last {
                display: inline-block;
            } 
        }
        
        .#{$pluginName}__toggle{
            display: inline-block;
            
            a:before {
                content: $expand;
            }
        }
    }
  
    .#{$pluginName}__toggle {
        display: none;
    }
}