/*.block:not(.block__shop__product) {display:none;}*/

.block__shop__search {
    position:relative;
    padding:0!important;

    .block__back {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:block;
        
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: var(--focal-x, 50%) var(--focal-y, 50%);
        }
    }    
    
    .block__front {
        margin-top:0;
        margin-bottom:0;
        position:relative;
        z-index: 2;
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:100%;

        .block__header {
            padding-top: calc(var(--header-height) + 7rem);
            padding-bottom: 4rem;
            color: var(--color-white-hex);
            text-align:center;

            div[class^="col"] {
                > * {
                    + * {
                        margin-top:1rem;
                    }
                }
            }
        }
        
        .block__body {
            position:relative;
            margin-top:0;

            &::after {
                content:"";
                display:block;
                width:100%;
                height:50%;
                position:absolute;
                bottom:0;
                background-color: var(--color-white-hex);
            }
            
            .search {
                margin: 0 auto;
                display:table;
                border:0;
                background-color: #fff;
                position:relative;
                z-index:1;
                width:100%;
                
                border-radius:5rem;
                box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
                0 2px 2px rgba(0,0,0,0.05), 
                0 4px 4px rgba(0,0,0,0.05), 
                0 4px 4px rgba(0,0,0,0.05);
            
                @include media-breakpoint-up(xl) {
                    width:50%;
                }
            
                > * {display:table-cell;}
            
                .search__input {
                    input {
                        height:60px;
                        font-size:1.25rem;
                        background:none;
                        border:0;
                        width:100%;
                        padding-left:0;
                        padding-right:2rem;
                        
                        &:focus {
                            outline: none;
                        }

                        &::-webkit-search-cancel-button{
                            -webkit-appearance: none;
                            height: 1.25rem;
                            width: 1.25rem;
                            margin-left: .4em;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM191.4 168.8l-22.6 22.6 11.3 11.3L233.4 256l-53.3 53.3-11.3 11.3 22.6 22.6 11.3-11.3L256 278.6l53.3 53.3 11.3 11.3 22.6-22.6-11.3-11.3L278.6 256l53.3-53.3 11.3-11.3-22.6-22.6-11.3 11.3L256 233.4l-53.3-53.3-11.3-11.3z'/%3E%3C/svg%3E");
                            cursor: pointer;
                        }
                    }
                }

                .search__button {
                    width:4rem;
            
                    button {
                        width:100%;
                        height:60px;
                        border:0;
                        background: none;
                        font-weight:100;
                        padding-left:1rem;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        
                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
            }
        }
    }
}


.block__shop__searchCategories {

    h2 {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
        text-align: center;
    }

    .filter--search {
        margin-top:1rem;

        .form-group {
            fieldset {
                display:flex;
                gap:.5rem;
                flex-wrap:wrap;
                justify-content:center;

                .form-check {
                    display:inline-block;
                    
                    input:checked {
                        + label {
                            border-color: var(--color-main-hex);
                        }
                    }                                 

                    label {
                        padding-left:0;
                        border-color: var(--color-custom-3-hex);
                        padding: .35rem .75rem;
                        display:flex;
                        align-items: flex-start;

                        &:hover,
                        &:focus-visible {
                            border-color: var(--color-custom-3-hover-hex);
                        }

                        span {
                            font-size:1rem;

                            + span {
                                font-size:.6rem;
                            }
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


/* Content */

.block__list__content {    

    .block__header {
        h2 {
            font-size:1.5rem;
        }
    }

    .block__body {
        .list {
            .listelement {
                a {
                    .listelement__media {
                        border-radius: var(--border-radius-default);
                        overflow:hidden;
                    }

                    .listelement__body {
                        padding:0;

                        .category {
                            font-size:1rem;
                        }

                        .title {
                            font-size:1.25rem;
                            font-weight:600;
                        }
                    } 
                }
            }
        }
    }
}


/* Departments */

.block__list__departments {
    .block__header {
        h2 {
            font-size:1.5rem;
        }
    }

    .block__body {
        .list {
            .listelement {
                .listelement__header {
                    display:flex;
                    flex-direction: column;
                    gap:2rem;
                    
                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        align-items:flex-end;
                        justify-content: space-between;    
                    }

                    .listelement__header__left {
                        flex:1;
                        
                        h3 {
                            font-size:1.25rem;
                        }

                        ul {
                            @include reset-ul;
                            display:flex;
                            gap:1rem;
                            flex-wrap:wrap;

                            li {
                                @include reset-li;

                                a {
                                    position:relative;
                                    padding-left:1.5rem;
                                    font-size:.875rem;
                                    font-weight:600;

                                    &::before {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }

                                    &.link--phone {
                                        &::before {
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2202 16.1423C17.2202 16.1423 16.2546 17.0906 16.018 17.3686C15.6325 17.78 15.1784 17.9742 14.583 17.9742C14.5258 17.9742 14.4647 17.9742 14.4075 17.9704C13.274 17.898 12.2207 17.4562 11.4307 17.0792C9.27058 16.0356 7.37382 14.5541 5.79764 12.6764C4.49624 11.1111 3.6261 9.66381 3.04982 8.10989C2.6949 7.16155 2.56514 6.42268 2.62238 5.7257C2.66055 5.28009 2.83229 4.91066 3.14905 4.59454L4.45045 3.2958C4.63745 3.12061 4.8359 3.02539 5.03054 3.02539C5.27098 3.02539 5.46561 3.17012 5.58774 3.29199C5.59155 3.2958 5.59537 3.29961 5.59919 3.30342C5.83199 3.52051 6.05334 3.74522 6.28614 3.98516C6.40445 4.10704 6.52658 4.22891 6.6487 4.3546L7.69058 5.39435C8.09512 5.79806 8.09512 6.17131 7.69058 6.57502C7.57991 6.68547 7.47305 6.79592 7.36237 6.90256C7.04179 7.2301 7.29363 6.97878 6.9616 7.27586C6.95396 7.28347 6.94633 7.28728 6.94252 7.2949C6.6143 7.62244 6.67537 7.94236 6.74406 8.15946C6.74788 8.17088 6.75169 8.18231 6.75551 8.19373C7.02648 8.84881 7.40812 9.46581 7.98821 10.2009L7.99203 10.2047C9.04536 11.4996 10.1559 12.5089 11.381 13.282C11.5375 13.3811 11.6978 13.461 11.8504 13.5372C11.9878 13.6058 12.1176 13.6705 12.2282 13.7391C12.2435 13.7467 12.2588 13.7581 12.274 13.7657C12.4038 13.8305 12.5259 13.861 12.6519 13.861C12.9686 13.861 13.1671 13.6629 13.232 13.5982L13.98 12.8516C14.1098 12.7221 14.3159 12.566 14.5563 12.566C14.7929 12.566 14.9876 12.7145 15.1059 12.844C15.1097 12.8478 15.1097 12.8478 15.1135 12.8516L17.2164 14.9502C17.6094 15.3386 17.2202 16.1423 17.2202 16.1423Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                    
                                    &.link--location {
                                        &::before {
                                            content:url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0031 18.5C10.0031 18.5 16.2639 12.9348 16.2639 8.76087C16.2639 5.30309 13.4608 2.5 10.0031 2.5C6.54527 2.5 3.74219 5.30309 3.74219 8.76087C3.74219 12.9348 10.0031 18.5 10.0031 18.5Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M12.0033 8.50013C12.0033 9.6047 11.1079 10.5001 10.0033 10.5001C8.89874 10.5001 8.00331 9.6047 8.00331 8.50013C8.00331 7.39556 8.89874 6.50013 10.0033 6.50013C11.1079 6.50013 12.0033 7.39556 12.0033 8.50013Z' stroke='%230D2240' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                                        }
                                    }
                                }
                            }

                            &.contact-info {
                                margin-top:1.5rem;

                            }

                            &.tags {
                                margin-top:1.5rem;

                                li {
                                    a {
                                        text-decoration: none;
                                        position:relative;
                                        font-size:.875rem;
                                        font-weight:600;
                                        padding:.5rem .75rem .5rem 1.75rem;
                                        background: var(--color-custom-3-hex);
                                        border-radius:5rem;

                                        &::before {
                                            position: absolute;
                                            top: 50%;
                                            left: .5rem;
                                            transform:translateY(-50%);
                                        }

                                        &.link--interior {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 9.5V9.5C3.61287 10.7257 4.86566 11.5 6.23607 11.5H9.76393C11.1343 11.5 12.3871 10.7257 13 9.5V9.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M6.26556 9.5L9.73444 9.5C10.9374 9.5 11.8682 8.44564 11.719 7.25193L11.219 3.25193C11.0939 2.25107 10.2431 1.5 9.23443 1.5L6.76556 1.5C5.75692 1.5 4.90612 2.25107 4.78101 3.25193L4.28101 7.25193C4.1318 8.44564 5.06257 9.5 6.26556 9.5Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M8 11.5V14.5M12 15.5V15.5C12 14.9477 11.5523 14.5 11 14.5H5C4.44772 14.5 4 14.9477 4 15.5V15.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 5.5V9.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--audiovideo {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66406 4.75C2.66406 3.50736 3.68741 2.5 4.94978 2.5L11.045 2.5C12.3074 2.5 13.3307 3.50736 13.3307 4.75L13.3307 12.25C13.3307 13.4926 12.3074 14.5 11.045 14.5L4.94978 14.5C3.68741 14.5 2.66406 13.4926 2.66406 12.25L2.66406 4.75Z' stroke='%230D2240' stroke-width='1.5' stroke-linejoin='round'/%3E%3Cpath d='M8.05137 4.75H7.9974V4.79805H8.05137V4.75Z' stroke='%230D2240' stroke-width='1.5' stroke-linejoin='round'/%3E%3Cpath d='M10.2831 10C10.2831 11.2426 9.25976 12.25 7.9974 12.25C6.73503 12.25 5.71168 11.2426 5.71168 10C5.71168 8.75736 6.73503 7.75 7.9974 7.75C9.25976 7.75 10.2831 8.75736 10.2831 10Z' stroke='%230D2240' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--print {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.0026 12.5H3.33594C2.33594 12.5 1.33594 11.5002 1.33594 10.5C1.33594 10.5 1.33594 8.39553 1.33594 6.8335C1.33594 5.8335 2.33594 4.8335 3.33594 4.8335C3.33594 4.8335 10.0026 4.8335 12.6693 4.8335C13.6693 4.8335 14.6693 5.8335 14.6693 6.8335C14.6693 7.8335 14.6693 9.49984 14.6693 10.5C14.6693 11.5002 13.6693 12.5 12.6693 12.5L11.0026 12.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M5 9.5L5 13.5C5 13.8333 5.2 14.5 6 14.5C6.8 14.5 8.66667 14.5 10 14.5C10 14.5 11 14.5 11 13.5V9.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M11.6641 4.8335L11.6641 2.8335C11.6641 2.50016 11.4641 1.8335 10.6641 1.8335C9.86406 1.8335 6.66406 1.8335 5.33073 1.8335C5.33073 1.8335 4.33073 1.8335 4.33073 2.8335L4.33073 4.8335' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M4 9.5L12 9.5' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M6.66406 12H9.33073' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M3.4375 6.91895H4.08154' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--it {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00156 11.6999V13.2999M5.60156 14.0999L10.4016 14.0999M3.20156 11.6999L12.8016 11.6999C13.6852 11.6999 14.4016 10.9836 14.4016 10.0999V4.4999C14.4016 3.61625 13.6852 2.8999 12.8016 2.8999L3.20156 2.8999C2.31791 2.8999 1.60156 3.61625 1.60156 4.4999L1.60156 10.0999C1.60156 10.9836 2.31791 11.6999 3.20156 11.6999Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--coffee {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 4.5C2 3.94771 2.44772 3.5 3 3.5L11 3.5C11.5523 3.5 12 3.94772 12 4.5L12 11.5C12 13.1569 10.6569 14.5 9 14.5H5C3.34315 14.5 2 13.1569 2 11.5L2 4.5Z' stroke='%230D2240' stroke-width='1.5'/%3E%3Cpath d='M12 10.5C14.5 10.5 15.0011 8.5 15.0011 7.5C15.0011 6.5 14 5.5 13 5.5H12.4297' stroke='%230D2240' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--misc {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00156 11.6999V13.2999M5.60156 14.0999L10.4016 14.0999M3.20156 11.6999L12.8016 11.6999C13.6852 11.6999 14.4016 10.9836 14.4016 10.0999V4.4999C14.4016 3.61625 13.6852 2.8999 12.8016 2.8999L3.20156 2.8999C2.31791 2.8999 1.60156 3.61625 1.60156 4.4999L1.60156 10.0999C1.60156 10.9836 2.31791 11.6999 3.20156 11.6999Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .listelement__header__right {
                        
                        display:flex;
                        gap:1rem;
                        flex-wrap:wrap;

                        @include media-breakpoint-up(lg) {
                            width:20rem;
                            justify-content:right;
                        }
                    }
                }
            }
        }
    }
}


/* Employees */

.block__list__people {
    .block__header {
        h2 {
            font-size:1.5rem;
        }
    }

    .block__body {
        .list {
            .listelement {
        
                .listelement__body {
                    display:flex;
                    gap:1.25rem; 
                    
                    padding:0;

                    @include media-breakpoint-up(lg) {
                        align-items:center;
                    }

                    .listelement__media {
                        width:5rem;
                        margin:0;

                        @include media-breakpoint-up(lg) {
                            width:6.25rem;
                        }

                        figure {
                            aspect-ratio: 1/1;    
                            border-radius: var(--border-radius-default);
                            overflow: hidden;

                            img {
                                width:100%;
                                height:100%;
                                object-fit: center center;
                            }
                        }
                    }

                    .listelement__meta {
                        flex:1;

                        @include media-breakpoint-up(lg) {
                            display:flex;
                        }

                        .listelement__meta1 {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            justify-content: center;
                            margin:0;

                            @include media-breakpoint-up(lg) {
                                width:30%;
                            }

                            h3 {
                                font-size:2rem;
                            }
                        }

                        .listelement__meta2 {
                            margin:0;

                            @include media-breakpoint-up(lg) {
                                flex:1;
                            }

                            ul {
                                @include reset-ul;
                                
                                li {
                                    @include reset-li;

                                    a {
                                        text-decoration:none;
                                        position:relative;
                                        padding-left:1.75rem;

                                        &::before {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                        }

                                        &.link--phone {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2202 16.1423C17.2202 16.1423 16.2546 17.0906 16.018 17.3686C15.6325 17.78 15.1784 17.9742 14.583 17.9742C14.5258 17.9742 14.4647 17.9742 14.4075 17.9704C13.274 17.898 12.2207 17.4562 11.4307 17.0792C9.27058 16.0356 7.37382 14.5541 5.79764 12.6764C4.49624 11.1111 3.6261 9.66381 3.04982 8.10989C2.6949 7.16155 2.56514 6.42268 2.62238 5.7257C2.66055 5.28009 2.83229 4.91066 3.14905 4.59454L4.45045 3.2958C4.63745 3.12061 4.8359 3.02539 5.03054 3.02539C5.27098 3.02539 5.46561 3.17012 5.58774 3.29199C5.59155 3.2958 5.59537 3.29961 5.59919 3.30342C5.83199 3.52051 6.05334 3.74522 6.28614 3.98516C6.40445 4.10704 6.52658 4.22891 6.6487 4.3546L7.69058 5.39435C8.09512 5.79806 8.09512 6.17131 7.69058 6.57502C7.57991 6.68547 7.47305 6.79592 7.36237 6.90256C7.04179 7.2301 7.29363 6.97878 6.9616 7.27586C6.95396 7.28347 6.94633 7.28728 6.94252 7.2949C6.6143 7.62244 6.67537 7.94236 6.74406 8.15946C6.74788 8.17088 6.75169 8.18231 6.75551 8.19373C7.02648 8.84881 7.40812 9.46581 7.98821 10.2009L7.99203 10.2047C9.04536 11.4996 10.1559 12.5089 11.381 13.282C11.5375 13.3811 11.6978 13.461 11.8504 13.5372C11.9878 13.6058 12.1176 13.6705 12.2282 13.7391C12.2435 13.7467 12.2588 13.7581 12.274 13.7657C12.4038 13.8305 12.5259 13.861 12.6519 13.861C12.9686 13.861 13.1671 13.6629 13.232 13.5982L13.98 12.8516C14.1098 12.7221 14.3159 12.566 14.5563 12.566C14.7929 12.566 14.9876 12.7145 15.1059 12.844C15.1097 12.8478 15.1097 12.8478 15.1135 12.8516L17.2164 14.9502C17.6094 15.3386 17.2202 16.1423 17.2202 16.1423Z' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--email {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6.63906V14.3596C2.5 15.4493 3.33947 16.3327 4.375 16.3327H15.625C16.6605 16.3327 17.5 15.4493 17.5 14.3596V6.63905M2.5 6.63906C2.5 5.54938 3.33947 4.66602 4.375 4.66602H15.625C16.6605 4.66602 17.5 5.54938 17.5 6.63905M2.5 6.63906L8.83698 11.1687C9.53261 11.6659 10.4674 11.6659 11.163 11.1687L17.5 6.63905' stroke='%230D2240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                                            }
                                        }

                                        &.link--linkedin {
                                            &::before {
                                                content:url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.01323 17.9999H2.5V7.44434H6.01852V17.9999H6.01323Z' fill='%230D2240'/%3E%3Cpath d='M4.25926 5.68502C3.28595 5.68502 2.5 4.8945 2.5 3.92576C2.5 2.95703 3.28595 2.1665 4.25926 2.1665C5.22799 2.1665 6.01852 2.95703 6.01852 3.92576C6.01852 4.89907 5.23256 5.68502 4.25926 5.68502Z' fill='%230D2240'/%3E%3Cpath d='M18.3368 17.9999H14.9541V12.9895C14.9541 11.7947 14.9287 10.2579 13.1966 10.2579C11.4339 10.2579 11.1639 11.5586 11.1639 12.9028V17.9999H7.78125V7.69967H11.0264V9.10644H11.0722C11.5256 8.29707 12.6311 7.44434 14.2766 7.44434C17.7 7.44434 18.3368 9.57857 18.3368 12.3536V17.9999Z' fill='%230D2240'/%3E%3C/svg%3E%0A");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}