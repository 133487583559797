.block__accordion {
    .block__body {
        .accordion {
            --bs-accordion-bg: transparent;
            
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        color: var(--bs-body-color);
                        font-size: 1.5rem;
                        font-weight: 500;
                        padding-inline: 0;
                        
                        /* Minus */
                        &::after {
                            @include fontawesome;
                            @include transition;
                            content:"\f068";
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            transform:rotate(-180deg) translateY(50%);
                            background-image: none;
                            opacity:0;
                            line-height:1;
                        }

                        /* Plus */
                        &::before {
                            @include fontawesome;
                            @include transition;
                            content:"\2b";
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            transform: translateY(-50%);
                            opacity:1;
                            line-height:1;
                        }

                        &:focus {
                            box-shadow: initial;
                        }

                        &[aria-expanded="true"] {
                            background-color: transparent;
                            color:var(--bs-body-color);

                            &::after {
                                transform:rotate(0deg) translateY(-50%);
                                opacity:1;
                            }

                            &::before {
                                transform:rotate(-180deg) translateY(50%);
                                opacity:0;
                            }
                        }
                    }
                }

                .accordion-body {
                    padding-inline: 0;
                }
            }
        }
    }
}