// Custom Container Widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1370px
);

$grid-row-columns: 12;

// Import vendors CSS
//@import 'vendors/bootstrap/bootstrap.scss';
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/swiper/swiper-bundle.min";
@import "node_modules/jarallax/dist/jarallax";
@import "node_modules/magnific-popup/dist/magnific-popup";
@import "node_modules/superfish/dist/css/superfish";

// Import SASS Utils
@import 'abstracts/variables';
@import 'abstracts/mixins';

// Import base CSS
@import 'base/base';
@import 'base/accessibility';
@import 'base/globals';
@import 'base/typography';
@import 'base/navigation';

// Import layout CSS
@import 'layout/grid';
@import 'layout/header';
@import 'layout/header_fixed';
@import 'layout/footer';

// Import component CSS
@import 'components/breadcrumbSmart';
@import 'components/buttons';
@import 'components/images';
@import 'components/modals';
@import 'components/offcanvas';
@import 'components/pagination';
@import 'components/tabs';
@import 'components/toast';

// Import block CSS
@import 'blocks/block';
@import 'blocks/block_accordion';
@import 'blocks/block_accordiontab';
//@import 'blocks/block_activity';
//@import 'blocks/block_alert';
@import 'blocks/block_blockquote';
@import 'blocks/block_breadcrumb';
@import 'blocks/block_buttons';
@import 'blocks/block_col';
@import 'blocks/block_form';
@import 'blocks/block_form_custom';
//@import 'blocks/block_form_sidebar';
@import 'blocks/block_hero';
@import 'blocks/block_info';
@import 'blocks/block_imagerow';
@import 'blocks/block_inspiration';
@import 'blocks/block_list';
@import 'blocks/block_list_articles';
@import 'blocks/block_list_documents';
@import 'blocks/block_list_employees';
/*@import 'blocks/block_list_events';*/
@import 'blocks/block_list_features';
@import 'blocks/block_list_figures';
@import 'blocks/block_list_gallery';
@import 'blocks/block_list_icons';
/*@import 'blocks/block_list_locations';*/
@import 'blocks/block_list_logos';
@import 'blocks/block_list_manufacturers';
/*@import 'blocks/block_list_message';*/
/*@import 'blocks/block_list_related';*/
/*@import 'blocks/block_list_searchresult';*/
@import 'blocks/block_list_shortcuts';
@import 'blocks/block_list_swipe';
/*@import 'blocks/block_map';*/
/*@import 'blocks/block_preHeader';*/
@import 'blocks/block_manufacturer';
@import 'blocks/block_profile';
/*@import 'blocks/block_scrollspy';*/
//@import 'blocks/block_search';
@import 'blocks/block_shortcutsWithImage';
@import 'blocks/block_shortcutsWithIcon';
@import 'blocks/block_shop_cart';
@import 'blocks/block_shop_cart_empty';
@import 'blocks/block_shop_category_slide';
@import 'blocks/block_shop_checkout';
@import 'blocks/block_shop_mypage';
@import 'blocks/block_shop_mypage_wishlist';
@import 'blocks/block_shop_product';
@import 'blocks/block_shop_product_list';
@import 'blocks/block_shop_product_wishlist';
@import 'blocks/block_shop_product_swipe';
@import 'blocks/block_shop_search';
@import 'blocks/block_slider';
@import 'blocks/block_subpageSelector';
/*@import 'blocks/block_tab';*/
@import 'blocks/block_text';
@import 'blocks/block_textAndImage';
@import 'blocks/block_title';
@import 'blocks/block_video';